import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

// Menu items structure with three levels
const menuItems = [
  { name: "หน้าแรก", link: "/", active: true },
  { name: "เกี่ยวกับเรา", link: "/about-me" },
  {
    name: "บริการติดตั้งโซล่าเซลล์",
    children: [{ name: "บริการติดตั้งโซล่าเซลล์ สำหรับบ้าน", link: "#" }],
  },
  {
    name: "แพ็คเกจโซล่าเซลล์",
    children: [
      {
        name: "String Inverter",
        children: [
          { name: "แพ็คเกจ 3.05 KW", link: "/package/str-3-kw" },
          { name: "แพ็คเกจ 5.49 KW", link: "/package/str-5-1-kw" },
          { name: "แพ็คเกจ 5.49 KW", link: "/package/str-5-3-kw" },
          { name: "แพ็คเกจ 10.98 KW", link: "/package/str-10-kw" },
          { name: "แพ็คเกจ 20.74 KW", link: "/package/str-20-kw" },
        ],
      },
      {
        name: "Micro Inverter",
        children: [
          { name: "แพ็คเกจ 2.44 KW", link: "/package/mic-2-kw" },
          { name: "แพ็คเกจ 4.88 KW", link: "/package/mic-4-kw" },
          { name: "แพ็คเกจ 7.32 KW", link: "/package/mic-7-kw" },
          { name: "แพ็คเกจ 9.76 KW", link: "/package/mic-9-kw" },
        ],
      },
    ],
  },
  { name: "โครงการ", link: "#" },
  { name: "ข่าวสาร", link: "#" },
  { name: "ติดต่อเรา", link: "#" },
];

const NavMenu = () => {
  const location = useLocation();
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState({});

  // Toggle mobile nav
  const handleToggle = () => {
    setIsMobileNavActive(!isMobileNavActive);
    setActiveDropdown({}); // Reset dropdowns when mobile nav is toggled
  };

  // Toggle dropdown in mobile nav
  const handleDropdownToggle = (index) => {
    setActiveDropdown((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Add or remove class from body based on nav state
  useEffect(() => {
    if (isMobileNavActive) {
      document.body.setAttribute("cz-shortcut-listen", "true");
      document.body.classList.add("mobile-nav-active");
    } else {
      document.body.removeAttribute("cz-shortcut-listen");
      document.body.classList.remove("mobile-nav-active");
    }
  }, [isMobileNavActive]);

  // Render nested menu items recursively (supporting three levels)
  const renderMenuItems = (items, parentIndex = "") => {
    return items.map((item, index) => {
      const currentIndex = `${parentIndex}${index}`;
      const isActive = location.pathname === item.link;

      return (
        <li key={index} className={`${isActive ? "active" : ""} ${item.children ? "drop-down" : ""}`}>
          <a
            href={item.link || "#"}
            onClick={(e) => {
              if (item.children) {
                e.preventDefault();
                handleDropdownToggle(currentIndex);
              }
            }}
          >
            {item.name} {/* Removed the dropdown icon */}
          </a>
          {item.children && <ul style={{ display: "block" }}>{renderMenuItems(item.children, `${currentIndex}-`)}</ul>}
        </li>
      );
    });
  };

  const renderMenuItemsMobiles = (items, parentIndex = "") => {
    return items.map((item, index) => {
      const currentIndex = `${parentIndex}${index}`;
      const isDropdownOpen = activeDropdown[currentIndex];

      return (
        <li key={index} className={`${item.active ? "active" : ""} ${item.children ? "drop-down" : ""} ${isDropdownOpen ? "open" : ""}`}>
          <a
            href={item.link || "#"}
            onClick={(e) => {
              if (item.children) {
                e.preventDefault();
                handleDropdownToggle(currentIndex);
              }
            }}
          >
            {item.name}
          </a>
          {item.children && (
            <ul style={{ display: isDropdownOpen ? "block" : "none" }}>{renderMenuItemsMobiles(item.children, `${currentIndex}-`)}</ul>
          )}
        </li>
      );
    });
  };

  return (
    <>
      {/* Header for both desktop and mobile */}
      <header id="header">
        <div className="logo float-left">
          <a href="/" className="scrollto">
            <img src={`${process.env.PUBLIC_URL}/img/logo-1.png`} alt="Logo" width={"200px"} height={'auto'} />
          </a>
        </div>
        <div className="container">
          {/* Mobile Navigation Toggle */}
          <button type="button" className="mobile-nav-toggle d-lg-none" onClick={handleToggle}>
            <i className={`fa ${isMobileNavActive ? "fa-times" : "fa-bars"}`}></i>
          </button>

          {/* Desktop Navigation */}
          <nav className="main-nav float-right d-none d-lg-block">
            <ul>{renderMenuItems(menuItems)}</ul>
          </nav>

          {/* Mobile Navigation (conditionally rendered based on state) */}
          {isMobileNavActive && (
            <nav className="mobile-nav d-lg-none">
              <ul>{renderMenuItemsMobiles(menuItems)}</ul>
            </nav>
          )}

          {/* Overlay when mobile nav is active */}
          {isMobileNavActive && <div className="mobile-nav-overly" onClick={handleToggle}></div>}
        </div>
      </header>
    </>
  );
};

export default NavMenu;
