import React from "react";

const About = () => {
  return (
    <section className="about" id="about">
      <div className="img-s-header-home">
        <h1 className="font-shadows">SOLAR FAMILY</h1>
        <h3 className="font-shadows">คุณพร้อมหรือยังที่จะใช้ไฟฟ้าแบบไม่ต้องกังวล</h3>
      </div>
    </section>
  );
};

export default About;
