import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer" className="section-bg">
      <div className="footer-top">
        <div className="container-fuild">
          <div className="row padding-footer">
            <div className="col-lg-4 text-left">
              <div className="text-left">
                <div className="img-logo-div" />
              </div>
            </div>
            <div className="col-lg-8 text-left">
              <div className="footer-newsletter">
                <h3>บริษัท พร้อม แฟมิลี่ เอ็นจิเนียริ่ง จำกัด</h3>
                <h3>Prom Family Engineering CO., LTD </h3>
                <h6>เลขที่ 102/190 ซอย สรงประภา 13 ตำบล/แขวง ดอนเมือง</h6>

                <h6>เราเป็นหนึ่งในผู้เชี่ยวชาญการติดตั้งโซลาร์เซลล์ ทั้งระบบ on-grid, off-grid และ Hybrid on/off grid แบบครบวงจร</h6>
              </div>
              <div class="social-links">
                <a href="#" class="facebook">
                  <i class="fa fa-facebook"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong>Prom Family Engineering</strong>. All Rights Reserved
        </div>
        <div className="credits">
          Designed by <a href="https://bootstrapmade.com/">NextGen Web Solution</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
