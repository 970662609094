import React from "react";
import AboutMeInPage from "../components/AboutMeInPage";
const { getImage } = require("../api/callApi"); // Import getImage

const AboutMePage = () => {

  // Fetch the image when the component mounts
  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await getImage("image/test1.jpg");
  //     if (!isEmpty(response)) {
  //       const { data } = response;
  //       // console.log("🚀 ~ fetchImage ~ base64Image:", data);
  //       if (response) {
  //         setImage(`data:${data.fileType};base64,${data.base64Image}`);
  //       }
  //     }
  //   };

  //   fetchImage();
  // }, []); // Empty dependency array ensures the effect runs only once

  return (
    <>
      <AboutMeInPage/>
      
    </>
  );
};

export default AboutMePage;
