import React from "react";

const AboutMe = () => {
  return (
    <section className="about wow fadeIn section-bg text-left" id="about-me">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h1 className="font-color-second">Prom Family Engineering</h1>
            <p>( Solar Family )</p>
            <span>
              เรา คือ บริษัทรับติดตั้งระบบโซลาร์เซลล์ (Solar Cell) แบบครบวงจร โดยการรวมตัวของกลุ่มวิศวกรที่มีความชื่นชอบ
              สนใจในระบบพลังงานแสงอาทิตย์ มีวิศวกรผู้เชี่ยวชาญทางด้านการออกแบบระบบโซลาร์เซลล์ที่มีประสบการณ์มากกว่า 10 ปี
              โดยยึดหลักมาตรฐานการออกแบบติดตั้งทางไฟฟ้า จากวิศวกรรมสถานแห่งประเทศไทย (วสท.) ทำให้มั่นใจได้ว่าผลงานที่ได้ติดตั้งกับเรา
              ปลอดภัย มีความน่าเชื่อถือ และรวมถึงการให้บริการหลังการขายแบบที่คุณประทับใจ
            </span>
            <div className="pt-4">
              <a href="/about-me" className="btn btn-get-started scrollto">
                เกี่ยวกับเรา
              </a>
            </div>
          </div>
          <div className="col-lg-6 pt-4">
            <div className="img-s-fam"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
