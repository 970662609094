import React from "react";

const Transection = () => {
  return (
    <section className="about wow fadeIn text-left" id="about-me">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1 className="font-color-second">เราดำเนินการอย่างไร ?</h1>
            <p>( Step By Step )</p>
            <hr />
            <div className="row justify-content-center">
              <div className="col-lg-2 d-flex flex-column justify-content-center align-items-center ">
                <div className="icon-01-1"></div>
                <h6 className="text-center mt-4">
                  สำรวจหน้างานและ
                  <br />
                  ออกแบบโครงการ
                </h6>
              </div>
              <div className="col-lg-2 d-flex flex-column justify-content-center align-items-center">
                <div className="icon-02-1"></div>
                <h6 className="text-center mt-4">
                  คำนวณระยะเวลา
                  <br />
                  การคืนทุน
                </h6>
              </div>
              <div className="col-lg-2 d-flex flex-column justify-content-center align-items-center">
                <div className="icon-03-1"></div>
                <h6 className="text-center mt-4">
                  ขออนุญาตติดตั้ง
                  <br />
                  กับหน่วยงานการไฟฟ้า
                </h6>
              </div>
              <div className="col-lg-2 d-flex flex-column justify-content-center align-items-center">
                <div className="icon-04-1"></div>
                <h6 className="text-center mt-4">ขอสิทธิประโยชน์ เช่น ขายไฟคืน, BOI</h6>
              </div>
              <div className="col-lg-2 d-flex flex-column justify-content-center align-items-center">
                <div className="icon-05-1"></div>
                <h6 className="text-center mt-4">
                  บริการหลังการขาย
                  <br />
                  ทั่วประเทศ
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Transection;
