import axios from 'axios'; // Use ES6 import syntax for axios

// Set up the base URL
const baseURL = 'https://api.promfamilyengineering.com/api/v1/';
// const localHost = 'http://localhost:5000/api/v1/';

// Arrow function to call the API for an image
export const getImage = async (imagePath) => {
  try {
    // Make the GET request
    const response = await axios.get(`${baseURL}${imagePath}`, {
      responseType: 'json', // To handle image data
    });
    // Return the image data
    
    return response;
  } catch (error) {
    // Handle any errors during the API call
    console.error('Error fetching image:', error.message);
  }
};