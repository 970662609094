import React from "react";

const AboutMeInPage = () => {
  return (
    <>
      <section className="about text-left" id="about-me-in-page-1">
        <div className="img-s-header">
          <div className="container">
            <div className="row ">
              <h4 className="font-shadows">เกี่ยวกับเรา</h4>
              <div className="line-dark-blue" />
            </div>
          </div>
        </div>
      </section>

      <section className="about section-bg text-left" id="about-me-in-page-2">
        <div className="container">
          <div className="row pt-4">
            <div className="col-lg-12">
              <h1 className="font-color-second">บริษัท พร้อม แฟมิลี่ เอ็นจิเนียร์ริ่ง จำกัด </h1>
              <h1 className="font-color-second">Prom Family Engineering CO., LTD </h1>

              <p>( Solar Family )</p>
              <span>
                มุ่งสู่การเป็นผู้นำในการติดตั้งระบบโซลาร์เซลล์ เพื่อเป็นการลดภาระค่าไฟฟ้าภายในครัวเรือน หรือลดต้นทุนการผลิตของผู้ประกอบการ
                ให้มั่นใจด้วยพลังงานสะอาด และรักษ์โลก
              </span>
              <span>เราเป็นหนึ่งในผู้เชี่ยวชาญการติดตั้งโซลาร์เซลล์ ทั้งระบบ on-grid, off-grid และ Hybrid on/off grid แบบครบวงจร</span>
              <span>
                โดยมีวิศวกรผู้เชี่ยวชาญทางด้านการออกแบบระบบโซลาร์เซลล์ที่มีประสบการณ์มากกว่า 10 ปี โดยยึดหลักมาตรฐานการออกแบบติดตั้งทางไฟฟ้า
                จากวิศวกรรมสถานแห่งประเทศไทย (วสท.)
              </span>
              <span>
                เราประกอบธุรกิจด้านพลังงาน โดยมุ่งเน้นเรื่องการติดตั้งโซลาร์เซลล์กลุ่มลูกค้าบ้านพักอาศัย โฮมออฟฟิศ ธุรกิจขนาดเล็กถึงขนาดกลาง
                สวนเพื่อการเกษตร และพัฒนาค้นหาเทคโนโลยีใหม่ด้านพลังงานที่เหมาะสมกับการใช้งานปัจจุบัน และอนาคต
                เพื่อส่งต่อพลังงานสะอาดให้กับเราทุกคน
              </span>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-lg-6">
              <h1 className="font-color-second">Prom Family Engineering</h1>
              <p>( Solar Family )</p>
              <span>
                เรา คือ บริษัทรับติดตั้งระบบโซลาร์เซลล์ (Solar Cell) แบบครบวงจร โดยการรวมตัวของกลุ่มวิศวกรที่มีความชื่นชอบ
                สนใจในระบบพลังงานแสงอาทิตย์ มีวิศวกรผู้เชี่ยวชาญทางด้านการออกแบบระบบโซลาร์เซลล์ที่มีประสบการณ์มากกว่า 10 ปี
                โดยยึดหลักมาตรฐานการออกแบบติดตั้งทางไฟฟ้า จากวิศวกรรมสถานแห่งประเทศไทย (วสท.) ทำให้มั่นใจได้ว่าผลงานที่ได้ติดตั้งกับเรา
                ปลอดภัย มีความน่าเชื่อถือ และรวมถึงการให้บริการหลังการขายแบบที่คุณประทับใจ
              </span>
            </div>
            <div className="col-lg-6 pt-4">
              <div className="img-s-fam"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutMeInPage;
