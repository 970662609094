import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
const { getImage } = require("../api/callApi"); // Import getImage

const PackagePage = () => {
  const { id } = useParams();
  const [packages, setPackages] = useState([]);
  const [items, setItems] = useState([]);
  const [itemPackage, setItemPackage] = useState([]);
  const list = [
    { id: "str-3-kw", name: "String Inverter แพ็คเกจ 3.05 KW", configCode: "STRING", packageCode: "PACKAGE00001" },
    { id: "str-5-1-kw", name: "String Inverter แพ็คเกจ 5.49 KW (1 phase)", configCode: "STRING", packageCode: "PACKAGE00002" },
    { id: "str-5-3-kw", name: "String Inverter แพ็คเกจ 5.49 KW (3 phase)", configCode: "STRING", packageCode: "PACKAGE00003" },
    { id: "str-10-kw", name: "String Inverter แพ็คเกจ 10.98 KW", configCode: "STRING", packageCode: "PACKAGE00004" },
    { id: "str-20-kw", name: "String Inverter แพ็คเกจ 20.74 KW", configCode: "STRING", packageCode: "PACKAGE00005" },
    { id: "mic-2-kw", name: "Micro Inverter แพ็คเกจ 2.44 KW", configCode: "MICRO", packageCode: "PACKAGE00006" },
    { id: "mic-4-kw", name: "Micro Inverter แพ็คเกจ 4.88 KW", configCode: "MICRO", packageCode: "PACKAGE00007" },
    { id: "mic-7-kw", name: "Micro Inverter แพ็คเกจ 7.32 KW", configCode: "MICRO", packageCode: "PACKAGE00008" },
    { id: "mic-9-kw", name: "Micro Inverter แพ็คเกจ 9.76 KW", configCode: "MICRO", packageCode: "PACKAGE00009" },
  ];

  const getListName = (id) => {
    const item = list.find((item) => item.id === id);
    return item ? item.name : "แพ็คเกจไม่พบ";
  };

  const getList = (id) => {
    const item = list.find((item) => item.id === id);
    return setItems(item);
  };

  const getSrcImage = () => {
    let newObj = packages?.find((obj) => obj?.configCode === items?.configCode);

    if (!newObj) {
      console.log("ไม่พบ config ที่ตรงกัน");
      return null;
    }

    let newPackage = newObj?.packages?.find((pkg) => pkg?.packageCode === items?.packageCode);

    if (!newPackage) {
      console.log("ไม่พบแพ็คเกจที่ตรงกัน");
      return null;
    }

    setItemPackage(newPackage);
  };

  useEffect(() => {
    fetch("https://api.promfamilyengineering.com/api/v1/get-all-package-types")
      .then((response) => response.json())
      .then((data) => {
        setPackages(data);
        getList(id);
      })
      .catch((error) => console.error("Error fetching package data:", error));
  }, []);

  useEffect(() => {
    getSrcImage();
  }, [items]);

  return (
    <>
      <section className="about text-left" id="package-page-1">
        <div className="img-s-header">
          <div className="container-fuild">
            <div className="row padding-title">
              <h4 className="font-shadows">{getListName(id)}</h4>
              <div className="line-title" />
            </div>
          </div>
        </div>
      </section>
      <section className="section-bg justify-content-center align-items-center" id="package-page-2">
        <div className="container-fuild">
          <div className="row padding-build">
            {/* <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="title-new">{getListName(id)}</h2>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-6">
                <div class="card card-new">
                  <div class="card-block">
                    <img
                      src={itemPackage.packageImageURL}
                      alt={`Image of ${itemPackage.packageName}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div class="card card-new">
                  <div class="card-block">
                    <img
                      src={itemPackage.packageDetailImageURL}
                      alt={`Detail of ${itemPackage.packageName}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PackagePage;
