import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Footer from "../components/Footer"; // Import Footer component
import Navbar from "../components/NavMenu"; // Import Navbar component
import AboutMePage from "../pages/AboutMePage";
import HomePage from "../pages/HomePage";
import PackagePage from "../pages/PackagePage";

// Import the pages

const AppRouter = () => {
  return (
    <Router>

      {/* Navbar */}
      <Navbar />
      <main id="main">
        {/* Use Switch for routing */}
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/about-me" component={AboutMePage} />
          <Route path="/package/:id" component={PackagePage} />
          {/* Add more routes as necessary */}
        </Switch>
      </main>
      {/* Footer */}
      <Footer />
    </Router>
  );
};

export default AppRouter;
