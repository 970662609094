import React, { useState } from "react";
import About from "../components/About";
import AboutMe from "../components/AboutMe";
import Transection from "../components/Transection";
const { getImage } = require("../api/callApi"); // Import getImage

const HomePage = () => {
  const [image, setImage] = useState(null); // State to store the Base64 image

  // Fetch the image when the component mounts
  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await getImage("image/test1.jpg");
  //     if (!isEmpty(response)) {
  //       const { data } = response;
  //       // console.log("🚀 ~ fetchImage ~ base64Image:", data);
  //       if (response) {
  //         setImage(`data:${data.fileType};base64,${data.base64Image}`);
  //       }
  //     }
  //   };

  //   fetchImage();
  // }, []); // Empty dependency array ensures the effect runs only once

  return (
    <>
      <About/>
      
      <AboutMe/>
      
      <Transection/>
    </>
  );
};

export default HomePage;
